import router from '@/router';
import Vue from 'vue';
import endpoints from '@/constants/endpoints';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import {
  UPDATE_PARTNER,
  GET_SELECTED_PARTNER,
  CREATE_PARTNER
} from '../actions/partner';

const state = {
  partnerSelected: {}
};

const actions = {
  async getSelectedPartner({ commit }, partner) {
    try {
      commit(GET_SELECTED_PARTNER, partner);
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },

  async updateGrandchild({ commit }, partner) {
    try {
      const data = {
        name: partner.name,
        partner_type: partner.partner_type
      };
      const res = await http.put(endpoints.updateGrandchild(partner.id), data);
      const updatedPartner = res.data;

      commit(UPDATE_PARTNER, updatedPartner);
      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'Partner has been updated'
      });

      router.push('/admin/childpool');
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },

  async createGrandchild({ commit }, grandchild) {
    try {
      const data = {
        name: grandchild.name,
        email: grandchild.email,
        partner_type: grandchild.partner_type
      };
      const res = await http.post(endpoints.createGrandchild, data);
      const partner = res.data;

      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'New childpool has been created'
      });
      router.push('/admin/childpool');
    }
    catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  }
};

const mutations = {
  [GET_SELECTED_PARTNER]: (state, partner) => (state.partnerSelected = partner),
  [UPDATE_PARTNER]: (state, partner) => (state.partnerSelected = partner)
};

export default {
  state,
  actions,
  mutations
};
