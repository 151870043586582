import _ from 'lodash';
import { underscored } from 'underscore.string';
import sanitizeHtml from 'sanitize-html';
import i18n from '@/i18n';
import { env } from '@/constants';

export const stringHelper = {
  padDigits(number, digits) {
    return Array(Math.max(digits - String(number).length + 1, 0)).join(0) + number;
  },
  underscoredUpper(text) {
    return text ? _.toUpper(underscored(text)) : text;
  },
  translateText(args) {
    const key = _.toArray(arguments)
      .map(x => stringHelper.underscoredUpper(x))
      .join('_');

    try {
      const result = i18n.t(key);

      return result;
    } catch (err) {
      return key;
    }
  },
  translateTextForDropdownList(items, prefix) {
    const result = items.map(item => {
      return {
        ...item,
        label: stringHelper.translateText(prefix, item.label),
      };
    });

    if (env.isDevelopment) {
      result.forEach(x => {
        // console.log(x.label);
      });
    }

    return result;
  },
  createMarkup(content) {
    const html = _.escape(content);

    return html;
  },
  createMarkupWithNewLine(content) {
    const html = _.escape(content).replace(/\n/g, '<br />');

    return html;
  },
  getCleanHtml(html) {
    const cleanHtml = sanitizeHtml(html);

    return cleanHtml;
  },
  trimHtml(html) {
    if (!html) {
      return '';
    }

    html = _.trimEnd(html.trim(), '<p><br></p>');

    return html;
  },
  formatNumber(value, defaultValue) {
    if (!value) {
      return defaultValue || 0;
    }

    const formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 5,
      minimumFractionDigits: 0,
    }).format(value);
    return formatter;
  },

};

export default stringHelper;
