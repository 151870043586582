import _ from 'lodash';
import store from '@/store';
import { MD5 } from 'crypto-es/lib/md5.js';

const DEFAULT_NUM_OF_DIGITS = 5;
const NUM_OF_DECIMAL_DIGITS = {
  '$': 2,
  USD: 2,
  USDT: 2,
  JPY: 2,
  BTC: 8,
  BTCSW: 8,
  ONT: 9,
  ONG: 18,
  ATOM: 6,
  IRIS: 6,
  ONE: 18,
  XTZ: 6,
  QTUM: 8,
  ADA: 6,
  TADA: 6,
};

const keyResolver = (currencies) => {
  const obj = { currencies };
  const key = MD5(JSON.stringify(obj)).toString();

  return key;
};

const getDecimalsCache = _.memoize((currencies) => {
  const result = _.reduce(currencies || [], (val, item) => {
    if (
      item.symbol === 'USDT' ||
      item.symbol === 'BTC' ||
      item.symbol === 'BTCSW' ||
      item.symbol === 'ETH'
    ) {
      val[item.symbol] = 0;
      return val;
    }

    val[item.symbol] = item.decimals;
    return val;
  }, {});

  return result;
}, keyResolver);

const getMappingCurrencySymbol = _.memoize(currencySymbol => {
  if (!currencySymbol) {
    return currencySymbol;
  }

  currencySymbol = currencySymbol.trim().toUpperCase();
  switch (currencySymbol) {
    case 'BTCSW':
      return 'BTC';

    case 'TADA':
      return 'ADA';

    default:
      return currencySymbol;
  }
});

export const currencyHelper = {
  getNumOfDecimalDigitCurrencies() {
    const currencies = store.getters.currencies;

    return getDecimalsCache(currencies);
  },
  getFormatDecimalDigits(value, decimalScale, currencySymbol) {
    if (value == 0) {
      return 0;
    }

    if (decimalScale !== null) {
      return decimalScale;
    }

    const currencies = store.getters.currencies;
    const DEFAULT_DECIMALS = getDecimalsCache(currencies);
    const numOfDecimalDigits = DEFAULT_DECIMALS[currencySymbol] || NUM_OF_DECIMAL_DIGITS[currencySymbol] || DEFAULT_NUM_OF_DIGITS;

    return numOfDecimalDigits;
  },
  getMappingCurrencySymbol: getMappingCurrencySymbol,
  mappingPlatform(platform) {
    switch (platform) {
      case 'BTCSW':
        return 'BTC';

      case 'TADA':
        return 'ADA';

      default:
        return platform;
    }
  },
  formatCurrency(value, currencySymbol, decimalDigits) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: decimalDigits,
      minimumFractionDigits: 0,
    });
    const text = formatter.format(value).replace('$', '') + ' ' + currencySymbol;

    return text;
  },
};

export default currencyHelper;
