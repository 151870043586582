import _ from 'lodash';
import qs from 'query-string';

export const loginHelper = {
  getLoginUrl() {
    const params = {
      redirectUrl: window.location.pathname + (window.location.search || ''),
    };

    return `/login?${qs.stringify(params)}`;
  },

};

export default loginHelper;
