export default {
  bold: 'Bold',
  underline: 'Underline',
  italic: 'Italic',
  forecolor: 'Color',
  bgcolor: 'Backcolor',
  strikethrough: 'Strikethrough',
  eraser: 'Eraser',
  source: 'Codeview',
  quote: 'Quote',
  fontfamily: 'Font family',
  fontsize: 'Font size',
  head: 'Head',
  orderlist: 'Ordered list',
  unorderlist: 'Unordered list',
  alignleft: 'Align left',
  aligncenter: 'Align center',
  alignright: 'Align right',
  link: 'Insert link',
  linkTarget: 'Open mode',
  text: 'Text',
  submit: 'Submit',
  cancel: 'Cancel',
  unlink: 'Unlink',
  table: 'Table',
  emotion: 'Emotions',
  img: 'Image',
  uploadImg: 'Upload',
  linkImg: 'Link',
  video: 'Video',
  width: 'width',
  height: 'height',
  location: 'Location',
  loading: 'Loading',
  searchlocation: 'search',
  dynamicMap: 'Dynamic',
  clearLocation: 'Clear',
  langDynamicOneLocation: 'Only one location in dynamic map',
  insertcode: 'Insert Code',
  undo: 'Undo',
  redo: 'Redo',
  fullscreen: 'Full screnn',
  openLink: 'open link',
  uploadPlaceTxt: 'uploading__',
  uploadTimeoutPlaceTxt: 'upload_timeout__',
  uploadErrorPlaceTxt: 'upload_error__'
};
