import en from './en.json';
import ja from './ja.json';

const messages = {
  en: {
    ...en,
  },
  ja: {
    ...ja,
  },
};

export default messages;
