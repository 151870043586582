import router from '@/router';
import Vue from 'vue';
import endpoints from '@/constants/endpoints';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';

const state = {
};

const actions = {
};

const mutations = {
};

export default {
  state,
  actions,
  mutations,
};
