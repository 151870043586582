import axios from 'axios';
import _ from 'lodash';
import qs from 'query-string';
import store from '@/store';
import Vue from 'vue';
import router from '@/router';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { env } from '@/constants';
import responseHelper from '@/utils/response-helper';
import loginHelper from '@/utils/login-helper';

export const http = axios.create({
  baseURL: env.app.apiUrl,
  // baseURL: 'http://localhost:3005',
  timeout: 60000,
  withCredentials: true,
});

// Add a request interceptor
http.interceptors.request.use(
  config => {
    const request = config;
    config.headers.common['Accept-Language'] = window.localStorage.getItem('locale') || env.app.locale;
    store.dispatch('setLoading', true);

    return config;
  },
  error => Promise.reject(error)
);

const goToLoginPage = _.debounce(() => {
  store.dispatch(AUTH_LOGOUT, true);

  const url = loginHelper.getLoginUrl();
  router.push(url);
}, 2000);

// Add a response interceptor
http.interceptors.response.use(
  response => {
    // Return JSON data
    store.dispatch('setLoading', false);
    if (response.data) {
      return response.data;
    }
    return response;
  },
  error => {
    // Attempt to get the actual error returned from API
    const err = (error.response && error.response.data) || error;
    store.dispatch('setLoading', false);

    if (error.response && (error.response.status === 401)) {
      if (error.response.data && error.response.data.code === 'LOGIN_FAIL') {
        // Vue.notify({
        //   group: 'notifications',
        //   type: 'error',
        //   title: 'Error',
        //   text: responseHelper.getErrorMessage(err),
        // });
      } else {
        goToLoginPage();
      }
    }

    if (error.response && error.response.status) {
      err.status = error.response.status;
    }

    return Promise.reject(err);
  }
);

export default http;
