export const endpoints = {
  // Grandchild
  getGrandchilds: '/web/grandchild',
  createGrandchild: '/web/grandchild',
  updateGrandchild: id => `/web/grandchild/${id}`,
  getGrandchild: id => `/web/grandchild/${id}`,

  // API key
  getAPIKeys: id => `/web/partners/${id}/keys`,
  createAPIKey: id => `/web/partners/${id}/keys`,
  revokeAPIKey: (partnerID, id) => `/web/partners/${partnerID}/keys/${id}`,

  // Memo
  getMemo: id => `/web/partners/${id}/memos`,
  updateMemo: id => `/web/partners/${id}/memos`,
  getMemoHistory: id => `/web/partners/${id}/memos/histories`,

  // Commissions
  getCommissions: partnerId => `/web/partners/${partnerId}/commissions`,
  updateCommissions: partnerId => `/web/partners/${partnerId}/commissions`,
  getCommissionHistory: partnerId => `/web/partners/${partnerId}/commissions/histories`,
  getStakingList: id => `/web/partners/${id}/stats`,

  // Childpool commissions
  getChildpoolCommissions: '/web/commissions',
  requestChangeCommissionAddress: commissionId => `/web/commissions/${commissionId}/requests`,
  approveChangeCommissionAddressRequest: '/web/commissions/requests',
  checkRequestToken: token => `/web/commissions/requests/check-token/${token}`,

  // Auth
  login: '/web/login',
  logout: '/web/logout',
  forgotPassword: '/web/forgot-password',
  setPassword: '/web/set-new-password',
  getSecret2Fa: '/web/me/2fa',
  update2Fa: '/web/me/2fa',
  confirm2Fa: '/web/confirm-2fa',
  confirmIp: '/web/confirm-ip',
  getLoginHistory: '/web/me/login-history',
  changePassword: '/web/me/change-password',
  getUserPermissions: '/web/me/permissions',
  updateProfileName: '/web/me/profile',

  // Users
  getUsers: 'web/users',
  deleteUser: id => `web/users/${id}`,
  resendActiveCode: id => `/web/users/${id}/resend-email`,
  getOperatorCountries: 'web/operator-countries',

  createUser: '/web/users',
  getUser: id => `/web/users/${id}`,
  updateUser: id => `/web/users/${id}`,
  activeUser: '/web/active-user',
  checkToken: token => `/web/check-token/${token}`,
  getProfile: '/web/me',

  // Roles
  addRoles: '/web/roles',
  getRoles: 'web/roles',
  updateRole: id => `/web/roles/${id}`,
  getPermissions: '/web/permissions',
  getRoleHasPermissions: id => `/web/roles/${id}/permissions`,
  getRolesForCurrentUser: '/web/roles-have-permission',

  // Members
  getMembers: '/web/membership/members',
  getMemberDetails: id => `/web/membership/members/${id}`,
  getMembershipTypes: '/web/membership/membership-types',
  updateReferrerCode: id => `/web/membership/members/${id}/referrer-codes`,
  updateMembershipTypeId: id => `/web/membership/members/${id}/membership-types`,
  getMemberTreeChart: id => `/web/membership/members/${id}/tree-chart`,
  getMemberStatuses: '/web/membership/member-order-status',
  getListKYC: '/web/membership/kycs',
  getKycStatuses: '/web/membership/member/kyc-statuses',
  sendEmailActiveMember: id => `/web/membership/members/${id}/resend-active-email`,
  updateStatus2FA: id => `/web/members/${id}/remove-2fa`,
  getListCountry: '/web/membership/countries',
  banMember: id => `/web/membership/members/${id}/ban`,
  unbanMember: id => `/web/membership/members/${id}/unban`,
  setTestingAccount: id => `/web/membership/members/${id}/set-testing-account`,

  // Crypto Addresses
  getCryptoAddresses: '/web/membership/crypto-addresses',
  getCryptoAddressesCSV: '/web/membership/crypto-addresses-csv',
  getPlatforms: '/web/currencies',

  getMemberKycs: id => `/web/membership/members/${id}/member-kycs`,
  dowloadCSVMember: 'web/membership/members-csv',
  getReferral: id => `/web/membership/members/${id}/referral-structure`,
  updateMemberKycProperties: id => `/web/membership/members/${id}/member-kyc-properties`,
  updateMemberKyc: (memberId, kycId) => `/web/membership/members/${memberId}/member-kycs/${kycId}`,
  getMaxReferences: id => `/web/membership/members/${id}/max-references`,
  updateMaxReferences: id => `/web/membership/members/${id}/max-references`,
  getKYCProperties: '/web/membership/kyc-properties',

  // Claim requests
  getClaimRequests: '/web/membership/claim-requests',
  updateClaimRequest: id => `/web/membership/claim-requests/${id}`,
  approveClaimRequests: '/web/membership//claim-requests/approves',
  downloadCsvClaimRequests: '/web/membership/claim-requests-csv',
  getClaimRequestDetails: id => `/web/membership/claim-requests/${id}`,
  updateTxIdClaimRequest: id => `/web/membership/claim-requests/${id}/txid`,
  uploadTxIdClaimRequest: '/web/membership/claim-requests/txid/csv',
  updatePayoutTransferredClaimRequest: claimRequestId => `/web/membership/claim-requests/${claimRequestId}/payout-transferred`,

  // Token payout
  getTokenPayout: '/web/affiliate/token-payout',
  downloadCsvTokenPayout: '/web/affiliate/token-payout-csv',
  approveTokenPayout: '/web/affiliate/token-payout/approves',
  getTokenPayoutDetails: id => `/web/affiliate/token-payout/detail/${id}`,
  updateTxIdTokenPayout: id => `/web/affiliate/token-payout/update/${id}/txid`,
  uploadTxIdTokenPayout: '/web/affiliate/token-payout/update/txid/csv',
  updatePayoutTransferred: tokenPayoutId => `/web/affiliate/token-payout/update/${tokenPayoutId}/payout-transferred`,
  // Membership orders
  getMembershipOrders: 'web/membership/orders',
  downloadCsvOrder: 'web/membership/orders-csv',
  getMembershipOrderDetails: id => `web/membership/orders/${id}`,
  updateDescriptionForMembershipOrder: id => `web/membership/orders/${id}/description`,
  rejectMembershipOrder: id => `web/membership//orders/rejects/${id}`,
  approveMembershipOrder: id => `web/membership//orders/approves/${id}`,
  getRejectOptions: groupName => `web/email-template-options?group_name=${groupName}`,

  // Membership config
  getPolicies: 'web/membership/policies',
  updatePolicy: id => `web/membership/policies/${id}`,
  getMembershipTypeConfig: '/web/membership/membership-type-config',
  updateMembershipTypeConfig: '/web/membership/membership-type-config',
  getHistoryFiatRate: '/web/membership/fiat-rate/histories',
  addFiatRate: '/web/membership/fiat-rate',

  // Email template
  getEmailTemplate: '/web/email-templates',
  getEmailDetail: name => `/web/email-templates/${name}`,
  updateEmailTemplate: '/web/email-templates',
  updateEmailTempalteOption: name => `/web/email-template-options/${name}`,
  getOptionTemplate: '/web/email-templates/reasons/group-names',
  createOptionsTemplate: '/web/email-template-options',
  duplicateTemplate: name => `/web/email-template-options/${name}/duplicate`,
  deleteTemplate: name => `/web/email-template-options/${name}`,

  // Bank Account
  getBankAccount: '/web/membership/bank-account',
  getHistoryBankAccount: '/web/membership/bank-account/histories',
  updateBankAccount: '/web/membership/bank-account',

  // WILL BE REMOVED
  /** ** Tri Binh code start *** */
  getSettingCommission: '/web/settings/commissions',
  getSettingCommissionHistory: '/web/settings/commissions/histories',
  getRewardSetting: '/web/settings/rewards',
  getRewardSettingHistory: '/web/settings/rewards/histories',
  getComDistribute: '/web/settings/commissions',
  getComDistributeHistory: '/web/settings/commissions/histories',
  updateComDistribute: '/web/settings/commissions',
  /** ** Tri Binh code end *** */

  // Receiving Address
  getListReceivingAddress: '/web/membership/receiving-address',
  createReceivingAddress: '/web/membership/receiving-address',
  getReceivingAddressDetails: id => `/web/membership/receiving-address/${id}`,
  updateReceivingAddressDetails: id => `/web/membership/receiving-address/${id}`,
  activeReceivingAddress: id => `/web/membership/receiving-address/${id}/active`,
  deactiveReceivingAddress: id => `/web/membership/receiving-address/${id}/disable`,

  // Update condition
  getPropertyUpgradeCondition: '/web/membership/upgrade-condition',
  updateUpgradeCondition: '/web/membership/upgrade-condition',

  // Affiliate config
  getAffiliatePolicies: '/web/membership/affiliate-policies',
  createAffiliatePolicy: '/web/membership/affiliate-policies',
  getAffiliatePolicyTypes: '/web/membership/affiliate-policy-types',
  getStakingCurrencyList: '/web/affiliate/staking-currencies',
  getPolicyDetails: id => `/web/membership/affiliate-policies/${id}`,
  updateAffiliatePolicy: id => `/web/membership/affiliate-policies/${id}`,

  // Caculate reward requests
  getCaculateRewardRequests: '/web/membership/caculate-reward-requests',
  getCaculateRewardDetails: id => `/web/membership/caculate-reward-requests/${id}`,
  getCaculateRewardListDetails: id => `/web/membership/caculate-reward-requests/${id}/details`,
  getCaculateRewardRequestStatus: '/web/membership/caculate-reward-request-status',
  getCaculateRewardItemDetails: id => `/web/membership/caculate-reward-request/${id}/details/rewards`,

  // Membership Reward Setting
  getMembershipRewardSetting: '/web/membership/reward-setting',
  updateMembershipRewardSetting: '/web/membership/reward-setting',

  // Affiliate Reward Setting
  getAffiliateRewardSetting: '/web/affiliate/claim-reward-setting',
  updateAffiliateRewardSetting: '/web/affiliate/claim-reward-setting',

  // Terms
  getTerms: '/web/terms',
  getTermDetails: termNo => `/web/terms/${termNo}`,
  updateTerm: termNo => `/web/terms/${termNo}`,
  createTerm: '/web/terms',

  // ada-pool-notify
  getAdaPoolNotify: '/web/ada-pool-notify',
  updateAdaPoolNotify: '/web/ada-pool-notify',
  getAdaPoolNotificationHistories: '/web/ada-pool-notify/histories',

  // Member assets
  getMemberAssets: '/web/member-assets',
  dowloadCSVMemberAssets: '/web/member-assets-csv',
  downloadLatestCSVMemberAssets: '/web/member-assets-csv-latest',

  // All assets
  getAllAssets: '/web/assets',
  dowloadAllAssetsCSV: '/web/assets-csv',

  // Exchange
  getExchangeCurrencies: '/web/exchange-currencies',
  getExchangeCurrencyDetails: currencyId => `/web/exchange-currencies/${currencyId}`,
  updateExchangeCurrency: currencyId => `/web/exchange-currencies/${currencyId}`,
  createExchangeCurrency: '/web/exchange-currencies',
  getExchangePlatforms: '/web/exchange-currencies/platforms',
  getExchangeCurrencyStatuses: '/web/exchange-currencies/statuses',

  // Moonpay
  getMoonpayCurrencies: '/web/moonpay-currencies',
  getMoonpayCurrencyDetails: currencyId => `/web/moonpay-currencies/${currencyId}`,
  createMoonpayCurrency: '/web/moonpay-currencies',
  updateMoonpayCurrency: currencyId => `/web/moonpay-currencies/${currencyId}`,
  getMoonpayPlatforms: '/web/moonpay-currencies/platforms',
  getMoonpayCurrencyStatuses: '/web/moonpay-currencies/statuses',

  // Exchange Transaction
  dowloadCSVExchangeTransaction: '/web/exchange-transactions/exchange-transactions-csv',
  getExchangeTransactions: '/web/exchange-transactions',
  getExchangeTransactionsStatuses: '/web/exchange-transactions/statuses',
  getExchangeTransactionDetail: exchangeTransactionId => `/web/exchange-transactions/${exchangeTransactionId}/details`,

  // Currency
  getAllCurrencies: '/web/all-currencies',
  getCurrencies: '/web/currencies',
  getCurrencyDetails: currencyId => `/web/currencies/${currencyId}`,
  updateCurrency: currencyId => `/web/currencies/${currencyId}`,
  createCurrency: '/web/currencies',
  getDropdownPlatforms: '/web/currencies/platforms',
  getDropdownCurrencyStatuses: '/web/currencies/statuses',
  getStakingPlatforms: '/web/currencies/staking-platforms',

  // Tracking Email
  getEmailTrackings: '/web/email-trackings',
  getEmailTrackingsDetails: id => `/web/email-trackings/${id}/details`,
  getEmailTrackingStatuses: '/web/email-trackings/statuses',

  // Notifications
  getNotifications: '/web/notifications',
  createNotification: '/web/notifications',
  getNotificationEvent: '/web/notifications/events',
  getNotificationType: '/web/notifications/types',
  editNotification: id => `/web/notifications/${id}`,
  deleteNotification: id => `/web/notifications/${id}`,
  getNotificationDetails: id => `/web/notifications/${id}`,

  // MS Point Setting
  getMSPointSettings: '/web/ms-point/settings',
  getMSPointPhases: '/web/ms-point/phases',
  updateModeSettings: '/web/ms-point/settings/mode',
  updateClaimingSetting: '/web/ms-point/settings/claiming',
  updateStakingSetting: '/web/ms-point/settings/staking',
  updateExchangeSetting: '/web/ms-point/settings/exchange',
  updateUpgradeMembershipSetting: '/web/ms-point/settings/upgrading-membership-type',
  updateSurveySetting: '/web/ms-point/settings/survey',

  // MS Point Histories
  getMSPointHistories: '/web/claim-points',
  getMSPointStatuses: '/web/claim-point-statuses',
  getMSPointActions: '/web/claim-point-actions',

  // Logging
  getLoggings: 'web/loggings',
  deleteLogging: id => `/web/loggings/${id}`,
  getLoggingDetails: id => `/web/loggings/${id}`,

  // Questions
  getQuestions: '/web/questions',
  getQuestionsDetails: questionId => `/web/questions/${questionId}`,
  updateQuestions: questionId => `/web/questions/${questionId}`,
  createQuestions: '/web/questions',
  getQuestionsTypes: '/web/questions/types',
  getQuestionsCats: '/web/questions/categories',
  deleteQuestions: questionId => `/web/questions/${questionId}`,

  // Member login
  getMemberLogin: '/web/member-logins',

  // Fiat transaction
  searchFiatTransactions: '/web/fiat-transactions',
  getFiatTransactionStatues: '/web/fiat-transactions/statuses',
  getFiatTransactionPaymentMethods: '/web/fiat-transactions/payment-methods',
  downloadFiatTransactionsCSV: '/web/fiat-transactions/download-csv',
  getFiatTransactionsDetails: id => `/web/fiat-transactions/${id}/details`,
  getFiatTransactionProviders: 'web/fiat-transactions/providers',

  // Fiat transaction
  getNexoTransactions: '/web/nexo-transaction',
  getNexoTransactionsDetail: id => `/web/nexo-transaction/${id}/details`,
  getNexoTransactionsTypes: '/web/nexo-transaction/types',
  getNexoTransactionsStatuses: '/web/nexo-transaction/statuses',

  // Nexo Member
  getNexoMembers: '/web/nexo-members',
  downloadNexoMemberCSV: '/web/nexo-members/csv',

  // Surveys/Quiz
  getQuizzes: '/web/quizzes',
  getQuizDetails: id => `/web/quizzes/${id}`,
  deleteQuiz: id => `/web/quizzes/${id}`,
  updateQuiz: id => `/web/quizzes/${id}`,
  createDraftQuiz: '/web/draft-quizzes',
  updateSurvey: id => `/web/surveys/${id}`,
  createDraftSurvey: '/web/draft-surveys',
  getSurveysTypes: '/web/questions/types',
  getSurveysCats: '/web/questions/categories',
  getSurveyOption: '/web/quizzes/options',

  // top affiliate
  getTopAffiliate: '/web/affiliate/get-top',

  // Campaigns
  getCampaigns: '/web/campaigns',
  createCampaign: '/web/campaigns',
  updateCampaign: id => `/web/campaigns/${id}`,
  deleteCampaign: id => `/web/campaigns/${id}`,
  getCampaignDetails: id => `/web/campaigns/${id}`,

  // Dapp
  getDappList: '/web/dapps',
  createDapp: '/web/dapps',
  updateDapp: id => `/web/dapps/${id}`,
  deleteDapp: id => `/web/dapps/${id}`,
  getDappDetails: id => `/web/dapps/${id}`,

  // Staking DAO
  getStakingDaoInstanceList: '/web/staking-dao/instances',
  getStakingDaoInstanceDetails: id => `/web/staking-dao/instances/${id}`,
  activeStakingDaoInstance: id => `/web/staking-dao/instances/${id}/active`,
  resetRetriesStakingDaoInstance: id => `/web/staking-dao/instances/${id}/reset-tries`,
  cancelTimeLockForDaoInstance: id => `/web/staking-dao/instances/${id}/cancel-time-lock`,
  renewTimeLockForDaoInstance: id => `/web/staking-dao/instances/${id}/renew-time-lock`,
  changeOperators: id => `/web/staking-dao/instances/${id}/operators`,
  getOperators: '/web/staking-dao/operators',
};

export default endpoints;
