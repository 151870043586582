import router from '@/router';
import Vue from 'vue';
import endpoints from '@/constants/endpoints';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';

import {
  UPDATE_USER,
  GET_SELECTED_USER,
  CREATE_USER,
  GET_ACTION
} from '../actions/user';
const state = {
  userSelected: {},
  action: ''
};

const actions = {
  async getSelectedUser({ commit }, user) {
    try {
      commit(GET_SELECTED_USER, user);
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  async updateUser({ commit }, user) {
    const updateUser = user;
    try {
      const data = {
        user_sts: updateUser.status,
        name: updateUser.name,
        // email: updateUser.email,
        role_id: updateUser.role_id,
        country_code: updateUser.country_code,
      };
      const res = await http.put(endpoints.updateUser(updateUser.id), data);
      const user = res.data;
      commit(UPDATE_USER, user);
      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'User has been updated'
      });
      router.push('/admin/user-management');
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  async createUser({ commit }, user) {
    const newUser = user;

    try {
      const data = {
        email: newUser.email,
        name: newUser.name,
        role_id: newUser.role_id,
        country_code: newUser.country_code,
      };
      const res = await http.post(endpoints.createUser, data);
      const user = res.data;
      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'New user has been created'
      });
      router.push('/admin/user-management');
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  async reSendActiveCode({ commit }, user) {
    try {
      const res = await http.post(endpoints.resendActiveCode(user.id));

      if (res.data) {
        Vue.notify({
          group: 'notifications',
          type: 'success',
          title: 'Success',
          text: `New active code has been sent to email ${user.email}`,
        });
      }

    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  getAction({ commit }, action) {
    commit(GET_ACTION, action);
  }
};
const mutations = {
  [GET_SELECTED_USER]: (state, user) => (state.userSelected = user),
  [UPDATE_USER]: (state, user) => (state.userSelected = user),
  [GET_ACTION]: (state, action) => (state.action = action)
};
export default {
  state,
  actions,
  mutations
};
