import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import auth from './modules/auth';
import user from './modules/user';
import partner from './modules/partner';
import platform from './modules/platform';
import setting from './modules/setting';
import theme from './modules/theme';
import order from './modules/order';
import role from './modules/role';
import member from './modules/member';
import currency from './modules/currency';
import notification from './modules/notification';
import loading from './loading';
import { env } from '@/constants';

Vue.use(Vuex);

const appVersionKey = 'app_version_key';
// Clear all states
if (env.app.version !== window.localStorage.getItem(appVersionKey)) {
  window.localStorage.clear();

  window.localStorage.setItem(appVersionKey, env.app.version);
}

const persistenceOptions = {
  key: ['vuex', env.app.name, env.app.version].join('-'),
  storage: window.localStorage,
};

export default new Vuex.Store({
  ...loading,
  modules: {
    auth,
    theme,
    setting,
    currency,
    platform,
    partner,
    user,
    role,
    order,
    member,
    notification,
  },
  plugins: [new VuexPersistence(persistenceOptions).plugin],
});
