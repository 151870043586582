import router from '@/router';
import Vue from 'vue';
import { endpoints, RouterName, env } from '@/constants';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import {
  SETTINGS_GET_MEMBERSHIP_TYPES,
  SETTINGS_GET_MEMBERSHIP_TYPES_SUCCESS,
  SETTINGS_UPDATE_LOCALE,
} from '@/store/actions/setting';

const state = {
  locale: env.app.locale,
  commisionAddress: null,
  membershipTypes: [],
};

const getters = {
  membershipTypes: state => state.membershipTypes,
  locale: state => state.locale,
};

const actions = {
  [SETTINGS_GET_MEMBERSHIP_TYPES]: async (context) => {
    try {
      const result = await http.get(endpoints.getMembershipTypes);
      const items = result.data;

      context.commit(SETTINGS_GET_MEMBERSHIP_TYPES_SUCCESS, items);
    }
    catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },

};

const mutations = {
  [SETTINGS_UPDATE_LOCALE]: (state, locale) => {
    state.locale = locale;
    window.localStorage.setItem('locale', locale);
  },
  [SETTINGS_GET_MEMBERSHIP_TYPES_SUCCESS]: (state, data) => {
    state.membershipTypes = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
