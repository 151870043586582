import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import Vue from 'vue';
import http from '@/utils/http';

import {
  GET_NOTIFICATION_EVENT,
  GET_NOTIFICATION_TYPE,
} from '../actions/notification';

const state = {
  notificationEvent: {},
  notificationType: {},
};
const actions = {
  async getNotificationType({ commit }) {
    try {
      const result = await http.get(endpoints.getNotificationType);
      const data = stringHelper.translateTextForDropdownList(result.data, 'NOTIFICATION_TYPE');
      commit(GET_NOTIFICATION_TYPE, data);
      return data;
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  async getNotificationEvent({ commit }) {
    try {
      const result = await http.get(endpoints.getNotificationEvent);
      const data = stringHelper.translateTextForDropdownList(result.data, 'NOTIFICATION_EVENT');
      commit(GET_NOTIFICATION_EVENT, data);
      return data;
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
};

const mutations = {
  [GET_NOTIFICATION_EVENT]: (state, notification) => (state.notificationEvent = notification),
  [GET_NOTIFICATION_TYPE]: (state, notification) => (state.notificationType = notification),
};
export default {
  state,
  actions,
  mutations
};
