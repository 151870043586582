import router from '@/router';
import Vue from 'vue';
import { endpoints, env } from '@/constants';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';

const state = {
  themeName: env.theme.name,
};

const actions = {

};

const mutations = {
};

export default {
  state,
  actions,
  mutations
};
