import _ from 'lodash';
import i18n from '@/i18n';

export const responseHelper = {
  getErrorMessage(err) {
    const errMsg = err.message ? err.message : err;
    const errCode = err.code ? err.code : err;
    const key = _.isString(errCode) ? errCode : errMsg;

    try {
      const result = i18n.t(errCode || errMsg, err.data || {}) || errMsg;

      return result;
    } catch (err) {
      return errMsg;
    }
  },
  getMessageByCode(code, data) {
    try {
      const result = i18n.t(code, data || {});

      return result;
    } catch (err) {
      return code;
    }
  },
};

export default responseHelper;
