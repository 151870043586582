import _ from 'lodash';

const NODE_ENV = process.env.NODE_ENV || 'development';
const isProduction = Boolean(process.env.PRODUCTION === 'true');

export const env = {
  node: process.env.NODE_ENV,
  envName: process.env.VUE_APP_ENV_NAME,
  isProduction,
  isTest: process.env.NODE_ENV === 'test',
  isDevelopment: process.env.NODE_ENV === 'development',
  app: {
    name: process.env.VUE_APP_NAME,
    apiUrl: process.env.VUE_APP_BASE_URL || '',
    version: process.env.VUE_APP_VERSION || '',
    description: process.env.VUE_APP_PACKAGE_DESCRIPTION || '',
    buildDate: process.env.BUILD_DATE || '',
    buildNumber: process.env.VUE_APP_BUILD_NUMBER || process.env.CI_PIPELINE_ID || '',
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    isShownviewTreeChart: process.env.VUE_APP_IS_SHOWN_VIEW_TREE_CHART === 'true',
    pageSize: parseInt(process.env.VUE_APP_PAGE_SIZE || '25'),
  },
  recaptcha: {
    key: process.env.VUE_APP_SITEKEY,
    isDisabled: process.env.VUE_APP_IS_DISABLED_CAPTCHA == 'true',
  },
  theme: {
    name: _.trim(process.env.VUE_APP_THEME_NAME).toLowerCase(),
    moonstake: {
      name: process.env.VUE_APP_THEME_MOONSTAKE_NAME,
      title: process.env.VUE_APP_THEME_MOONSTAKE_TITLE,
    },
    binarystar: {
      name: process.env.VUE_APP_THEME_BINARYSTAR_NAME,
      title: process.env.VUE_APP_THEME_BINARYSTAR_TITLE,
    },
  },
  policy: {
    membershipAffiliateMaxLevels: process.env.VUE_APP_MEMBERSHIP_AFFILIATE_MAX_LEVELS || 5,
    maxLevelsOfMembershipPolicy: process.env.VUE_APP_MAX_LEVELS_OF_MEMBERSHIP_POLICY || 5,
  },
  currencies: {
    testnetCurrencies: (process.env.VUE_APP_TESTNET_CURRENCIES || '').split(','),
  },
  emailLanguages: (process.env.VUE_APP_EMAIL_LANGUAGES || '').split(','),
  changellyTrackUrl: process.env.VUE_APP_CHANGELLY_EXPLORER || 'https://changelly.com/track/',
  wyreTrackUrl: process.env.VUE_APP_WYRE_EXPLORER || 'https://dash.sendwyre.com/track/',
  moonpaySellUrl: process.env.VUE_APP_MOONPAY_SELL_EXPLORER || 'https://sell.moonpay.com/transaction_receipt',
  moonpayBuyUrl: process.env.VUE_APP_MOONPAY_BUY_EXPLORER || 'https://buy.moonpay.com/transaction_receipt',
  ssv: {
    explorerUrl: process.env.SSV_EXPLORER_URL,
    isTestnet: process.env.SSV_IS_TESTNET === 'true',
  },
};

console.log('=======================================================');
console.log(
  ` ${env.app.name} version: ${env.app.version}. Build #${env.app.buildNumber} ${env.app.description}, date: ${env.app.buildDate}`,
);
console.log('=======================================================');
console.log('%c%s', 'color: white; background: red; font-size: 24px;', '  STOP!   ');
console.log('This browser feature is intended for developers.');
