import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import Vue from 'vue';
import App from './App';
import router from './router';
import store from './store';
import babelPolyfill from 'babel-polyfill';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import 'vue2-org-tree/dist/style.css';
import Notifications from 'vue-notification';
// import { extend } from "vee-validate";
// import { required, email, confirmed } from "vee-validate/dist/rules";

import { extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import VueProgressBar from 'vue-progressbar';
import VueCurrencyInput from 'vue-currency-input';
import Moment from 'vue-moment';
import http from './utils/http';
import i18n from '@/i18n';
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import moment from 'moment';
import Vue2Filters from 'vue2-filters';
import Vue2OrgTree from 'vue2-org-tree';
import '@/directives/indeterminate';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import YimoVueEditor from 'yimo-vue-editor';
import yimoVueEditorLang from '@/assets/yimo-vue-editor-lang';
import currencyHelper from '@/utils/currency-helper';
import 'vue2-datepicker/index.css';
import semver from 'semver';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { env } from '@/constants';

Vue.use(VueReCaptcha, {
  siteKey: env.recaptcha.key,
});

Vue.prototype.$http = http;
const pluginOptions = {
  globalOptions: { precision: 3, currency: null }
};
Vue.use(VueCurrencyInput, pluginOptions);
Vue.use(Moment);
Vue.use(CoreuiVue);
Vue.use(Notifications);
Vue.use(VueProgressBar, {
  color: '#321fdb',
  failedColor: 'red',
  height: '2px'
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('datetime', Datetime);
Vue.use(Vue2Filters);
Vue.use(Vue2OrgTree);

Vue.use(YimoVueEditor, {
  // name: 'v-editor-app',//Custom name
  config: {
    printLog: false, // disabled console.log
    lang: yimoVueEditorLang,
    menus: [
      'source',
      '|',
      'bold',
      'underline',
      'italic',
      'strikethrough',
      'eraser',
      'forecolor',
      'bgcolor',
      '|',
      'quote',
      'fontfamily',
      'fontsize',
      // 'head',
      'unorderlist',
      'orderlist',
      'alignleft',
      'aligncenter',
      'alignright',
      '|',
      'link',
      'unlink',
      'table',
      '|',
      'img',
      // 'video',
      'insertcode',
      '|',
      'undo',
      'redo',
    ],
    familys: [
      'Arial',
      'Verdana',
      'Georgia',
      'Times New Roman',
      'Microsoft JhengHei',
      'Trebuchet MS',
      'Courier New',
      'Impact',
      'Comic Sans MS',
      'Consolas',
    ],
    colors: {
      '#880000': 'Dark red',
      '#800080': 'Purple',
      '#ff0000': 'Red',
      '#ff00ff': 'Bright pink',
      '#000080': 'Dark blue',
      '#0000ff': 'Blue',
      '#00ffff': 'Lake Blue',
      '#008080': 'Blue-green',
      '#008000': 'Green',
      '#808000': 'Olive',
      '#00ff00': 'light green',
      '#ffcc00': 'Orange yellow',
      '#808080': 'Gray',
      '#c0c0c0': 'Silver',
      '#000000': 'Black',
      '#ffffff': 'White'
    },
  },
});

// Vee-Validate
// extend("required", required);
// extend("email", email);
// extend("confirmed",confirmed);

extend('rulesMinPassword', {
  message: field => 'Password length must be equals or greater than 6',
  validate: value => value.length >= 6
});

extend('checkTokenAddress', {
  message: field => 'Wrong Token Address',
  // eslint-disable-next-line no-undef
  validate: value => web.utils.isAddress(value)
});

extend('periodTime', {
  params: ['target'],
  validate(value, { target: fromDate }) {
    if (moment(value).toDate() < moment(fromDate).toDate()) {
      return false;
    }

    return true;
  },
  message: 'To date must greater than or equal from date.'
});

extend('futureDate', {
  validate(value) {
    const today = new Date();
    return moment(value).toDate() > today;
  },
  // message: 'Date must greater than today.'
});

extend('semver', {
  validate: value => !!semver.valid(value)
});

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // message: messages[rule] // assign message
  });
});

extend('json', {
  validate(value) {
    try {
      if (!value) {
        return true;
      };
      return JSON.parse(value) ? true : false;
    } catch (error) {
      return false;
    }
  },
});

configure({
  defaultMessage: (field, values) => {
    // console.log(field, values);
    // override the field name.
    let text = i18n.t(field);
    if (text !== field) {
      values._field_ = text;
    } else {
      const key = `validate_filed.${field}`;
      text = i18n.t(key);
      values._field_ = text !== key ? text : field;
    }

    return i18n.t(`validate_message.${values._rule_}`, values);
  }
});

// END

// filters
Vue.filter('truncate', function (value, limit) {
  if (value && value.length > limit) {
    value = value.substring(0, (limit - 3)) + '...';
  }

  return value;
});

Vue.filter('mappingPlatform', function (value) {
  return currencyHelper.mappingPlatform(value);
});

Vue.filter('formatNumber', function (value, decimalDigits = 0) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: decimalDigits,
    minimumFractionDigits: 0,
  });
  const text = formatter.format(value).replace('$', '');

  return text;
});


new Vue({
  el: '#app',
  router,
  babelPolyfill, // Must have this to use async await
  icons,
  store,
  i18n,
  template: '<App/>',
  components: {
    App
  }
});


const overrideNotify = () => {
  Vue.prototype.$notify = (options) => {
    checkUnauthorized(options);
  };

  Vue.notify = (options) => {
    checkUnauthorized(options);
  };
};

const originalNotifyFunction = Vue.prototype.$notify;
let flg = true;
function checkUnauthorized(options) {
  const { group, type, title, text, duration } = options;
  if (text === i18n.t('UNAUTHORIZED')) {
    if (flg) {
      flg = false;
      originalNotifyFunction(options);
      setTimeout(() => {
        flg = true;
      }, 3000);
    }
  } else {
    originalNotifyFunction(options);
  }
}
overrideNotify();
