import _ from 'lodash';
import Vue from 'vue';
import Router from 'vue-router';
import qs from 'query-string';
import store from '@/store';
import { RoleType, RouterName } from '@/constants';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import loginHelper from '@/utils/login-helper';
import responseHelper from '@/utils/response-helper';

// Containers
const TheContainer = () => import('@/containers/TheContainer');

// Views
const Dashboard = () => import('@/views/Dashboard');

const Charts = () => import('@/views/charts/Charts');
const Widgets = () => import('@/views/widgets/Widgets');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const SetPassword = () => import('@/views/pages/SetPassword');
const ForgotPassword = () => import('@/views/pages/ForgotPassword');
const Register = () => import('@/views/pages/Register');
const ActiveUser = () => import('@/views/pages/ActiveUser');
const ConfirmNewIp = () => import('@/views/pages/ConfirmNewIp');
const ConfirmRequest = () => import('@/views/pages/ConfirmRequest');
const ApproveRequest = () => import('@/views/pages/ApproveRequest');

// Users
const Users = () => import('@/views/users/Users');
const User = () => import('@/views/users/User');

// Childpool
const ChildPoolList = () => import('@/views/pages/admin/childPool/ChildPoolList');
const EditPool = () => import('@/views/pages/admin/childPool/EditPool');

// Childpool - Config
const ApiKeyManagement = () => import('@/views/pages/admin/childPool/ApiKeyManagement');
const TransactionMemo = () => import('@/views/pages/admin/childPool/TransactionMemo');
const Commission = () => import('@/views/pages/admin/childPool/Commission');

// User manament
const ListUser = () => import('@/views/pages/admin/userAccount/ListUser');
const EditUser = () => import('@/views/pages/admin/userAccount/EditUser');

const DetailPool = () => import('@/views/pages/admin/childPool/DetailPool');
// Profile
const Profile = () => import('@/views/pages/admin/profile/Profile');
const ChangePassword = () => import('@/views/pages/admin/profile/ChangePassword');
const Setting2FA = () => import('@/views/pages/admin/profile/Setting2FA');
const LoginHistory = () => import('@/views/pages/admin/profile/LoginHistory');

// Members
const MemberList = () => import('@/views/pages/admin/members/ListMember');
const MemberDetail = () => import('@/views/pages/admin/members/MemberDetail');
const TreeChart = () => import('@/views/pages/admin/members/TreeChart');
const Referral = () => import('@/views/pages/admin/members/ReferralStructure');

// CryptoAddresses
const CryptoAddressList = () => import('@/views/pages/admin/crypto-addresses/CryptoAddressList');

// Role
const ListRoles = () => import('@/views/pages/admin/roles/ListRoles');
const EditRole = () => import('@/views/pages/admin/roles/EditRole');

// Membership config
const BankAccount = () => import('@/views/pages/admin/membership-config/BankAccount');
const MembershipPolicy = () => import('@/views/pages/admin/membership-config/MembershipPolicy');
const MembershipTypes = () => import('@/views/pages/admin/membership-config/MembershipTypes');
const MembershipCondition = () => import('@/views/pages/admin/membership-config/MembershipCondition');
const ClaimMembershipRewardSetting = () => import('@/views/pages/admin/membership-config/ClaimMembershipRewardSetting');
const ExchangeRates = () => import('@/views/pages/admin/membership-config/ExchangeRate');

// Orders
const OrderList = () => import('@/views/pages/admin/orders/OrderList');
const OderDetails = () => import('@/views/pages/admin/orders/OderDetails');

// Claim requests
const ClaimRequestList = () => import('@/views/pages/admin/claim-requests/ClaimRequestList');
const ClaimRequestDetails = () => import('@/views/pages/admin/claim-requests/ClaimRequestDetails');

// MS Point History
const MSPointHistoryList = () => import('@/views/pages/admin/ms-point-history/MSPointHistoryList');

// Token payout
const TokenPayoutList = () => import('@/views/pages/admin/token-payout/TokenPayoutList');
const TokenPayoutDetails = () => import('@/views/pages/admin/token-payout/TokenPayoutDetail');

// top affiliate
const TopAffiliate = () => import('@/views/pages/admin/top-affiliate/TopAffiliateList');

// Receiving Address
const ReceivingAddress = () => import('@/views/pages/admin/receivingAddress/ReceivingAddress');
const AddReceivingAddress = () => import('@/views/pages/admin/receivingAddress/AddReceivingAddress');
const EditReceivingAddress = () => import('@/views/pages/admin/receivingAddress/AddReceivingAddress');

// Affiliate Polices
const AffiliatePolicies = () => import('@/views/pages/admin/affiliate-config/AffiliatePolicies');
const AddPolicy = () => import('@/views/pages/admin/affiliate-config/AddPolicy');
const EditPolicy = () => import('@/views/pages/admin/affiliate-config/EditPolicy');

// Reward requests
const RewardRequestList = () => import('@/views/pages/admin/affiliate-config/RewardRequestList');
const RewardRequestDetails = () => import('@/views/pages/admin/affiliate-config/RewardRequestDetails');

// Affiliate reward setting
const ClaimAffiliateRewardSetting = () => import('@/views/pages/admin/affiliate-config/ClaimAffiliateRewardSetting');

// Email template content
const EmailTemplate = () => import('@/views/pages/admin/email-template/ListEmailTemplate');
const EditEmailTemplate = () => import('@/views/pages/admin/email-template/EditEmailTemplate');
const AddEmailTemplateOption = () => import('@/views/pages/admin/email-template/AddEmailTemplateOption');
const EditEmailTemplateOption = () => import('@/views/pages/admin/email-template/AddEmailTemplateOption');

// Setting
const CommissionAddress = () => import('@/views/pages/admin/setting/CommissionAddress');
const ADANotifyConfig = () => import('@/views/pages/admin/setting/ADANotifyConfig');
const IOSTCampaignConfig = () => import('@/views/pages/admin/setting/IOSTCampaignConfig');

// Terms
const TermList = () => import('@/views/pages/admin/terms/TermList');
const AddTerm = () => import('@/views/pages/admin/terms/AddTerm');

// User assets
const UserAssetList = () => import('@/views/pages/admin/member-assets/UserAssetList');

// All assets
const AllAssetList = () => import('@/views/pages/admin/all-assets/AllAssetList');

// Exchange
const ExchangeCurrencyList = () => import('@/views/pages/admin/exchange-currencies/ExchangeCurrencyList');
const EditExchangeCurrency = () => import('@/views/pages/admin/exchange-currencies/EditExchangeCurrency');
const ExchangeTransactionList = () => import('@/views/pages/admin/exchange-transactions/ExchangeTransactionList');
const EditExchangeTransaction = () => import('@/views/pages/admin/exchange-transactions/EditExchangeTransaction');

// Moonpay
const MoonpayCurrencyList = () => import('@/views/pages/admin/moonpay/currency/MoonpayCurrencyList');
const EditMoonpayCurrency = () => import('@/views/pages/admin/moonpay/currency/EditMoonpayCurrency');
// Currency
const CurrencyList = () => import('@/views/pages/admin/currencies/CurrencyList');
const EditCurrency = () => import('@/views/pages/admin/currencies/EditCurrency');

// Question
const QuestionList = () => import('@/views/pages/admin/questions/QuestionList');
const EditQuestion = () => import('@/views/pages/admin/questions/EditQuestion');

// TrackingEmails
const TrackingEmailList = () => import('@/views/pages/admin/tracking-emails/TrackingEmailList');
const TrackingEmailDetail = () => import('@/views/pages/admin/tracking-emails/TrackingEmailDetail');

// Notification
const NotificationList = () => import('@/views/pages/admin/notification/NotificationList');
const EditNotification = () => import('@/views/pages/admin/notification/EditNotification');

// MS Point Setting
const MSPointSetting = () => import('@/views/pages/admin/ms-point-setting/MSPointSetting');

// Logging
const LoggingList = () => import('@/views/pages/admin/logging/LoggingList');
const LoggingDetail = () => import('@/views/pages/admin/logging/LoggingDetail');

// Fiat Transaction
const FiatTransactionList = () => import('@/views/pages/admin/fiat-transaction/FiatTransactionList');
const FiatTransactionDetail = () => import('@/views/pages/admin/fiat-transaction/FiatTransactionDetail');

// Nexo transaction
const NexoTransactionList = () => import('@/views/pages/admin/nexo-transaction/NexoTransactionList');
const NexoTransactionDetails = () => import('@/views/pages/admin/nexo-transaction/EditNexoTransaction');

// Nexo member
const NexoMemberList = () => import('@/views/pages/admin/nexo-member/NexoMemberList');

// Survey
const SurveyList = () => import('@/views/pages/admin/survey/SurveyList');
const EditQuiz = () => import('@/views/pages/admin/survey/EditQuiz');
const EditSurvey = () => import('@/views/pages/admin/survey/EditSurvey');

// DApp
const DAppList = () => import('@/views/pages/admin/dapp/DAppList');
const EditDApp = () => import('@/views/pages/admin/dapp/EditDApp');

// Staking DAO
const StakingDAO = () => import('@/views/pages/admin/staking-dao/StakingDao');
const InstanceDetails = () => import('@/views/pages/admin/staking-dao/InstanceDetails');
const StakingDaoChangeOperators = () => import('@/views/pages/admin/staking-dao/ChangeOperators');
Vue.use(Router);

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    return next();
  }

  next(loginHelper.getLoginUrl());
};

const hasPermission = requiredPermission => (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    return next(loginHelper.getLoginUrl());
  }

  const userPermissions = store.getters.userPermissions || [];
  const isAllowed = userPermissions.some(item => item.name === requiredPermission);
  if (!isAllowed) {
    if ((from.name || '').toUpperCase() === 'LOGIN') {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: responseHelper.getMessageByCode('ACCOUNT_PAGE_TITLE_NOTIFY_ERROR'),
        text: responseHelper.getMessageByCode('ACCOUNT_PAGE_DOES_NOT_HAVE_PERMISSION'),
        duration: 5000,
      });

      store.dispatch(AUTH_LOGOUT);

      next('/');
      return;
    } else {
      next('/admin');
      return;
    }
  }

  next();
};

export default new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: '/admin',
      redirect: () => {
        const userPartnerType = store.getters.userPartnerType;

        return userPartnerType === 'CHILD' ? '/admin/members' : '/admin/childpool';
      },
      name: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_HOME'),
      component: TheContainer,
      beforeEnter: ifAuthenticated,
      children: [
        {
          path: 'dashboard',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_DASHBOARD'),
          },
          component: Dashboard,
        },
        // Child pool
        {
          path: 'childpool',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CHILDPOOL'),
          },
          redirect: 'childpool',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('VIEW_LIST_GRANDCHILD'),
              component: ChildPoolList,
            },
            {
              path: 'create',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CHILDPOOL_CREATE'),
              },
              beforeEnter: hasPermission('CREATE_GRANDCHILD'),
              component: EditPool,
            },
            {
              path: 'edit/:id',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CHILDPOOL_EDIT'),
              },
              beforeEnter: hasPermission('UPDATE_GRANDCHILD'),
              component: EditPool,
            },
            {
              path: 'detail/:id',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CHILDPOOL_DETAIL'),
              },
              redirect: 'detail/:id',
              // beforeEnter: hasPermission('VIEW_PARTNER'),
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  component: DetailPool,
                },
                // ****** child pool Config start ******
                {
                  path: 'api-key',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_DETAIL_POOL_API_KEY_MANAGEMENT'),
                  },
                  component: ApiKeyManagement,
                },
                {
                  path: 'transaction-memo',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_DETAIL_POOL_TRANSACTION_MEMO'),
                  },
                  component: TransactionMemo,
                },
                {
                  path: 'commission',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_DETAIL_POOL_COMMISSION'),
                  },
                  component: Commission,
                },
                // ****** child pool Config end ******
              ],
            },
          ],
        },
        // User manament
        {
          path: 'user-management',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_OPERATOR_MANAGEMENT'),
          },
          redirect: 'user-management',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('VIEW_LIST_USER'),
              component: ListUser,
            },
            {
              path: 'create-user',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_GROUP_SETTING_OPERATOR_MANAGEMENT_ADD'),
              },
              beforeEnter: hasPermission('CREATE_USER'),
              component: EditUser,
            },
            {
              path: 'edit-user/:userId',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_GROUP_SETTING_OPERATOR_MANAGEMENT_EDIT'),
              },
              props: true,
              beforeEnter: hasPermission('UPDATE_USER'),
              component: EditUser,
            },
          ],
        },
        // Profile
        {
          path: 'profile',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PROFILE'),
          },
          redirect: 'profile',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              component: Profile,
            },
            {
              path: 'change-password',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUM_COMPONENT_PROFILE_CHANGE_PASSWORD'),
              },
              component: ChangePassword,
            },
            {
              path: 'setting-2fa',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUM_COMPONENT_PROFILE_SETTING_2FA'),
              },
              component: Setting2FA,
            },
            {
              path: 'login-history',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUM_COMPONENT_PROFILE_LOGIN_HISTORY'),
              },
              component: LoginHistory,
            },
          ],
        },
        // Members
        {
          path: 'members',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBER'),
          },
          redirect: 'members',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_MEMBER_LIST'),
              component: MemberList,
            },
            {
              path: ':memberId/details',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MEMBER_DETAIL'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_MEMBER_DETAIL'),
              component: MemberDetail,
            },
            {
              path: ':memberId/tree-chart',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MEMBER_TREE_CHART'),
              },
              component: TreeChart,
            },
            {
              path: ':memberId/referral',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MEMBER_REFFERAL'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_REFERRAL_STRUCTURE'),
              component: Referral,
            },
          ],
        },
        // crypto-addresses
        {
          path: 'crypto-addresses',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CRYPTO_ADDRESSES'),
          },
          redirect: 'crypto-addresses',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('VIEW_LIST_CRYPTO_ADDRESS'),
              component: CryptoAddressList,
            },
          ],
        },
        // Member assets
        {
          path: 'member-assets',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_USER_ASSETS'),
          },
          redirect: 'member-assets',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('VIEW_LIST_MEMBER_ASSET'),
              component: UserAssetList,
            },
          ],
        },
        // All assets
        {
          path: 'moonstake-assets',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_ALL_ASSETS'),
          },
          redirect: 'moonstake-assets',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('VIEW_ALL_ASSETS'),
              component: AllAssetList,
            },
          ],
        },
        // Roles
        {
          path: 'roles',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_ROLE_MANAGEMENT'),
          },
          redirect: 'roles',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('ROOT'),
              component: ListRoles,
            },
            {
              path: 'create',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_GROUP_SETTING_ROLE_MANAGEMENT_ADD'),
              },
              beforeEnter: hasPermission('ROOT'),
              component: EditRole,
            },
            {
              path: ':roleId/edit',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_GROUP_SETTING_ROLE_MANAGEMENT_EDIT'),
              },
              beforeEnter: hasPermission('ROOT'),
              component: EditRole,
            },
          ],
        },
        // Membership configuration
        {
          path: 'membership-settings',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_MEMBERSHIP_MEMBERSHIP_SETTING'),
          },
          redirect: 'membership-settings/bank-account',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'bank-account',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_BANK_ACCOUNT_REWARD'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_BANK_ACCOUNT_REWARD'),
              component: BankAccount,
            },
            {
              path: 'membership-payout-policy',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_MEMBERSHIP_POLICY_LIST'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_MEMBERSHIP_POLICY_LIST'),
              component: MembershipPolicy,
            },
            {
              path: 'membership-type',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_MEMBERSHIP_TYPE_CONFIG'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_MEMBERSHIP_TYPE_CONFIG'),
              component: MembershipTypes,
            },
            {
              path: 'upgrade-condition',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_UPGRADE_CONDITION'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_UPGRADE_CONDITION'),
              component: MembershipCondition,
            },
            {
              path: 'claim-membership-reward-setting',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CLAIM_MEMBERSHIP_REWARD_SETTING'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_CLAIM_MEMBERSHIP_REWARD_SETTING'),
              component: ClaimMembershipRewardSetting,
            },
            {
              path: 'receiving-address',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_VIEW_RECEIVING_ADDRESS'),
              },
              redirect: 'receiving-address',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('MEMBERSHIP_VIEW_RECEIVING_ADDRESS'),
                  component: ReceivingAddress,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MEMBERSHIP_VIEW_RECEIVING_ADDRESS_ADD'),
                  },
                  beforeEnter: hasPermission('MEMBERSHIP_ADD_RECEIVING_ADDRESS'),
                  component: AddReceivingAddress,
                },
                {
                  path: ':receivingAddressId/edit',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MEMBERSHIP_VIEW_RECEIVING_ADDRESS_EDIT'),
                  },
                  beforeEnter: hasPermission('MEMBERSHIP_UPDATE_RECEIVING_ADDRESS'),
                  component: EditReceivingAddress,
                },
              ],
            },
          ],
        },
        // Affiliate Management
        {
          path: 'affiliate-management',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_GROUP_AFFILIATE_MANAGEMENT'),
          },
          redirect: 'affiliate-management/token-payout',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'token-payout',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_TOKEN_PAYOUT'),
              },
              redirect: 'token-payout',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('AFFILIATE_TOKEN_PAYOUT_VIEW_LIST'),
                  component: TokenPayoutList,
                },
                {
                  path: ':TokenPayoutId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_TOKEN_PAYOUT_DETAIL'),
                  },
                  beforeEnter: hasPermission('AFFILIATE_TOKEN_PAYOUT_VIEW_DETAIL'),
                  component: TokenPayoutDetails,
                },
              ],
            },
            {
              path: 'reward-requests',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CACULATOR_REWARD_REQUEST'),
              },
              redirect: 'reward-requests',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_LIST'),
                  component: RewardRequestList,
                },
                {
                  path: ':rewardRequestId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CACULATOR_REWARD_REQUEST_DETAIL'),
                  },
                  beforeEnter: hasPermission('MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_DETAIL'),
                  component: RewardRequestDetails,
                },
              ],
            },
            {
              path: 'top-affiliate',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_TOP_AFFILIATE'),
              },
              beforeEnter: hasPermission('VIEW_TOP_AFFILIATE'),
              component: TopAffiliate,
            },
          ],
        },
        {
          path: 'exchange-rates',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_EXCHANGE_RATES'),
          },
          beforeEnter: hasPermission('MEMBERSHIP_VIEW_FIAT_RATE'),
          component: ExchangeRates,
        },
        // Affiliate configuration
        {
          path: 'affiliate-configuration',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_AFFILIATE_CONFIGURATION'),
          },
          redirect: 'affiliate-configuration/affiliate-policies',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'affiliate-policies',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_AFFILIATE_CONFIGURATION_POLICIES'),
              },
              redirect: 'affiliate-policies',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('MEMBERSHIP_VIEW_AFFILIATE_POLICY_LIST'),
                  component: AffiliatePolicies,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_AFFILIATE_CONFIGURATION_POLICIES_ADD'),
                  },
                  beforeEnter: hasPermission('MEMBERSHIP_CREATE_AFFILIATE_POLICY'),
                  component: AddPolicy,
                },
                {
                  path: ':policyId/edit',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_AFFILIATE_CONFIGURATION_POLICIES_EDIT'),
                  },
                  beforeEnter: hasPermission('MEMBERSHIP_UPDATE_AFFILIATE_POLICY'),
                  component: EditPolicy,
                },
              ],
            },
            {
              path: 'claim-affiliate-reward-setting',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CLAIM_REWARD_SETTING'),
              },
              beforeEnter: hasPermission('AFFILIATE_VIEW_CLAIM_REWARD_SETTING'),
              component: ClaimAffiliateRewardSetting,
            },
          ],
        },
        // Membership Orders
        {
          path: 'membership-orders',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_MEMBERSHIP_ORDERS'),
          },
          redirect: 'membership-orders',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_ORDER_LIST'),
              component: OrderList,
            },
            {
              path: ':orderId/details',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MEMBERSHIP_ORDERS_DETAIL'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_ORDER_DETAIL'),
              component: OderDetails,
            },
          ],
        },
        // Claim requests
        {
          path: 'commission-payout',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_CLAIM_REQUESTS'),
          },
          redirect: 'commission-payout',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_CLAIM_REQUEST_LIST'),
              component: ClaimRequestList,
            },
            {
              path: ':claimRequestId/details',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CLAIM_REQUESTS_DETAIL'),
              },
              beforeEnter: hasPermission('MEMBERSHIP_VIEW_CLAIM_REQUEST_DETAIL'),
              component: ClaimRequestDetails,
            },
          ],
        },
        {
          path: 'ms-point-histories',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MS_POINT_HISTORIES'),
          },
          beforeEnter: hasPermission('VIEW_CLAIM_MS_POINT_HISTORIES'),
          component: MSPointHistoryList,
        },
        // Exchange
        {
          path: 'exchange',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_EXCHANGE'),
          },
          redirect: 'exchange/currencies',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'currencies',
              name: 'exchange-currencies',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_EXCHANGE_CURRENCIES'),
              },
              beforeEnter: hasPermission('VIEW_EXCHANGE_CURRENCIES'),
              redirect: 'currencies',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_EXCHANGE_CURRENCIES'),
                  component: ExchangeCurrencyList,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CREATE_CURRENCIES'),
                  },
                  beforeEnter: hasPermission('CREATE_EXCHANGE_CURRENCY'),
                  component: EditExchangeCurrency,
                },
                {
                  path: ':currencyId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_EXCHANGE_CURRENCY_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_EXCHANGE_CURRENCIES'),
                  component: EditExchangeCurrency,
                },
              ],
            },
            {
              path: 'transactions',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_EXCHANGE_TRANSACTIONS'),
              },
              beforeEnter: hasPermission('VIEW_LIST_EXCHANGE_TRANSACTION'),
              redirect: 'transactions',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_EXCHANGE_TRANSACTION'),
                  component: ExchangeTransactionList,
                },
                {
                  path: ':transactionId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_EXCHANGE_TRANSACTION_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_EXCHANGE_TRANSACTION_DETAIL'),
                  component: EditExchangeTransaction,
                },
              ],
            },
            {
              path: 'fiat-transactions',
              name: 'fiat-transactions',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_LIST_FIAT_TRANSACTION'),
              },
              beforeEnter: hasPermission('VIEW_LIST_FIAT_TRANSACTION'),
              redirect: 'fiat-transactions',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_FIAT_TRANSACTION'),
                  component: FiatTransactionList,
                },
                {
                  path: ':id/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_FIAT_TRANSACTION_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_FIAT_TRANSACTION_DETAIL'),
                  component: FiatTransactionDetail,
                },
              ],
            },
            {
              path: 'nexo-transactions',
              name: 'nexo-transactions',
              redirect: 'nexo-transactions',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_NEXO_TRANSACTION'),
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_NEXO_TRANSACTION'),
                  component: NexoTransactionList,
                },
                {
                  path: ':id/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_NEXO_TRANSACTION_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_NEXO_TRANSACTION_DETAIL'),
                  component: NexoTransactionDetails,
                },
              ],
            },
            {
              path: 'nexo-members',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_LIST_NEXO_MEMBER'),
              },
              redirect: 'nexo-members',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_NEXO_MEMBER'),
                  component: NexoMemberList,
                },
              ],
            },
            {
              path: 'moonpay-currencies',
              name: 'moonpay-currencies',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_VIEW_MOONPAY_CURRENCIES'),
              },
              beforeEnter: hasPermission('VIEW_EXCHANGE_CURRENCIES'),
              redirect: 'moonpay-currencies',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_EXCHANGE_CURRENCIES'),
                  component: MoonpayCurrencyList,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CREATE_CURRENCIES'),
                  },
                  beforeEnter: hasPermission('CREATE_EXCHANGE_CURRENCY'),
                  component: EditMoonpayCurrency,
                },
                {
                  path: ':currencyId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MOONPAY_CURRENCY_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_EXCHANGE_CURRENCIES'),
                  component: EditMoonpayCurrency,
                },
              ],
            },
          ],
        },
        // Staking DAO
        {
          path: 'staking-dao',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_STAKING_DAO'),
          },
          redirect: 'staking-dao',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: '',
              name: '',
              beforeEnter: hasPermission('VIEW_LIST_STAKING_DAO_INSTANCE'),
              component: StakingDAO,
            },
            {
              path: ':id/details',
              meta: {
                label: responseHelper.getMessageByCode('STAKING_DAO_DETAILS_PAGE_BREADCRUM_TITLE'),
              },
              beforeEnter: hasPermission('VIEW_STAKING_DAO_INSTANCE_DETAIL'),
              component: InstanceDetails,
            },
            {
              path: ':id/change-operators',
              meta: {
                label: responseHelper.getMessageByCode('STAKING_DAO_CHANGE_OPERATORS_PAGE_BREADCRUM_TITLE'),
              },
              beforeEnter: hasPermission('CHANGE_OPERATORS_STAKING_DAO_INSTANCE'),
              component: StakingDaoChangeOperators,
            },
          ],
        },
        // Setting
        {
          path: 'setting',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_GROUP_SETTING_CHILDPOOL_SETTING'),
          },
          redirect: 'setting/commission-address',
          component: {
            render(c) {
              return c('router-view');
            },
          },
          children: [
            {
              path: 'commission-address',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_COMMISSION_ADDRESS'),
              },
              beforeEnter: hasPermission('CREATE_CHANGE_REWARD_ADDRESS_REQUEST'),
              component: CommissionAddress,
            },
            {
              path: 'email-templates',
              meta: {
                label: responseHelper.getMessageByCode('LIST_EMAIL_TEMPLATE_PAGE_BREADCRUM_LABEL'),
              },
              redirect: 'email-templates',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_EMAIL_TEMPLATE_LIST'),
                  component: EmailTemplate,
                },
                {
                  path: ':name/details',
                  meta: {
                    label: responseHelper.getMessageByCode('EDIT_EMAIL_TEMPLATE_PAGE_BREADCRUM_TITLE'),
                  },
                  beforeEnter: hasPermission('UPDATE_EMAIL_TEMPLATE'),
                  component: EditEmailTemplate,
                },
                {
                  path: ':groupName/create',
                  meta: {
                    label: responseHelper.getMessageByCode('CREATE_OPTION_EMAIL_TEMPLATE_BREADCRUM_TITLE'),
                  },
                  beforeEnter: hasPermission('CREATE_EMAIL_TEMPLATE'),
                  component: AddEmailTemplateOption,
                },
                {
                  path: ':name/email-tempalte-option-details',
                  meta: {
                    label: responseHelper.getMessageByCode('EDIT_OPTION_EMAIL_TEMPLATE_BREADCRUM_TITLE'),
                  },
                  beforeEnter: hasPermission('UPDATE_EMAIL_TEMPLATE'),
                  component: EditEmailTemplateOption,
                },
              ],
            },
            {
              path: 'terms',
              meta: {
                label: responseHelper.getMessageByCode('BREADCRUM_TERM_LIST_LABEL'),
              },
              redirect: 'terms',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_TERM'),
                  component: TermList,
                },
                {
                  path: ':termNo/details',
                  meta: {
                    label: responseHelper.getMessageByCode('BREADCRUM_TERM_DETAILS_LABEL'),
                  },
                  beforeEnter: hasPermission('VIEW_TERM_DETAIL'),
                  component: AddTerm,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('BREADCRUM_CREATE_TERM_LABEL'),
                  },
                  beforeEnter: hasPermission('CREATE_TERM'),
                  component: AddTerm,
                },
              ],
            },
            {
              path: 'ada-notify-config',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_ADA_NOTIFY_CONFIG'),
              },
              beforeEnter: hasPermission('VIEW_ADA_POOL_NOTIFY_CONFIG'),
              component: ADANotifyConfig,
            },
            {
              path: 'currencies',
              name: 'Currencies2',
              redirect: 'currencies',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_CURRENCY'),
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_CURRENCY'),
                  component: CurrencyList,
                },
                {
                  path: ':currencyId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CURRENCY_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_CURRENCY_DETAIL'),
                  component: EditCurrency,
                },
              ],
            },
            {
              path: 'tracking-emails',
              name: 'tracking-emails',
              redirect: 'tracking-emails',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_TRACKING_EMAILS'),
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LIST_EMAIL_TRACKING'),
                  component: TrackingEmailList,
                },
                {
                  path: ':id/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_TRACKING_EMAIL_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_EMAIL_TRACKING_DETAIL'),
                  component: TrackingEmailDetail,
                },
              ],
            },
            {
              path: 'notification',
              meta: {
                label: responseHelper.getMessageByCode('BREADCRUM_NOTIFICATION_LIST_LABEL'),
              },
              redirect: 'notification',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_NOTIFICATIONS'),
                  component: NotificationList,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('BREADCRUM_CREATE_NOTIFICATION_LABEL'),
                  },
                  beforeEnter: hasPermission('CREATE_NOTIFICATION'),
                  component: EditNotification,
                },
                {
                  path: 'edit/:id',
                  meta: {
                    label: responseHelper.getMessageByCode('BREADCRUM_UPDATE_NOTIFICATION_LABEL'),
                  },
                  beforeEnter: hasPermission('UPDATE_NOTIFICATION'),
                  component: EditNotification,
                },
              ],
            },
            {
              path: 'ms-point',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_MS_POINT_SETTING_LABEL'),
              },
              beforeEnter: hasPermission('VIEW_MS_POINT_SETTINGS'),
              redirect: 'logging',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_MS_POINT_SETTINGS'),
                  component: MSPointSetting,
                },
                {
                  path: 'create-quiz',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CREATE_QUIZ'),
                  },
                  beforeEnter: hasPermission('CREATE_SURVEY'),
                  component: EditQuiz,
                },
                {
                  path: 'create-survey',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CREATE_SURVEY'),
                  },
                  beforeEnter: hasPermission('CREATE_SURVEY'),
                  component: EditSurvey,
                },
                {
                  path: 'quizzes/:id/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_SURVEY_DETAILS'),
                  },
                  beforeEnter: hasPermission('UPDATE_SURVEY'),
                  component: EditQuiz,
                },
                {
                  path: 'surveys/:id/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_SURVEY_DETAILS'),
                  },
                  beforeEnter: hasPermission('UPDATE_SURVEY'),
                  component: EditSurvey,
                },
              ],
            },
            {
              path: 'logging',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_LOGGING'),
              },
              redirect: 'logging',
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_LOGGINGS'),
                  component: LoggingList,
                },
                {
                  path: 'details/:id',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_LOGGING_DETAIL'),
                  },
                  beforeEnter: hasPermission('VIEW_LOGGINGS'),
                  component: LoggingDetail,
                },
              ],
            },
            {
              path: 'questions',
              name: 'questions',
              redirect: 'questions',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_QUESTION'),
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_QUESTION_DETAILS'),
                  component: QuestionList,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CREATE_QUESTION'),
                  },
                  beforeEnter: hasPermission('CREATE_QUESTION'),
                  component: EditQuestion,
                },
                {
                  path: ':questionId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_QUESTION_DETAILS'),
                  },
                  beforeEnter: hasPermission('UPDATE_QUESTION'),
                  component: EditQuestion,
                },
              ],
            },
            {
              path: 'iost-campaign-config',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_SETTING_VIEW_IOST_CAMPAIGN_DETAIL'),
              },
              beforeEnter: hasPermission('VIEW_CAMPAIGN_DETAIL'),
              component: IOSTCampaignConfig,
            },
            {
              path: 'dapp',
              name: 'dapp',
              redirect: 'dapp',
              meta: {
                label: responseHelper.getMessageByCode('MEMBERSHIP_MENU_COMPONENT_DAPP'),
              },
              component: {
                render(c) {
                  return c('router-view');
                },
              },
              children: [
                {
                  path: '',
                  name: '',
                  beforeEnter: hasPermission('VIEW_DAPP_LIST'),
                  component: DAppList,
                },
                {
                  path: 'create',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_CREATE_DAPP'),
                  },
                  beforeEnter: hasPermission('CREATE_DAPP'),
                  component: EditDApp,
                },
                {
                  path: ':dappId/details',
                  meta: {
                    label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_DAPP_DETAILS'),
                  },
                  beforeEnter: hasPermission('VIEW_DAPP_DETAIL'),
                  component: EditDApp,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/',
      redirect: '/login',
      meta: {
        label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE'),
      },
      component: {
        render(c) {
          return c('router-view');
        },
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404,
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500,
        },
        {
          path: 'login',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_LOGIN'),
          },
          component: Login,
        },
        {
          path: 'forgot-password',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_LOGIN'),
          },
          component: ForgotPassword,
        },
        {
          path: 'set-new-password',
          props: true,
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_SET_PASSWORD'),
          },
          component: SetPassword,
        },
        {
          path: 'active-user',
          // props:true,
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_ACTIVE_USER'),
          },
          component: ActiveUser,
        },
        {
          path: 'register',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_REGISTER'),
          },
          component: Register,
        },
        {
          path: 'confirm-ip',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_CONFIRM_IP'),
          },
          component: ConfirmNewIp,
        },
        {
          path: 'confirm-request',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_CONFIRM_REQUEST'),
          },
          component: ConfirmRequest,
        },
        {
          path: 'approve-request',
          meta: {
            label: responseHelper.getMessageByCode('MEMBERSHIP_BREADCRUMB_COMPONENT_PAGE_APPROVE_REQUEST'),
          },
          component: ApproveRequest,
        },
      ],
    },
    { path: '*', component: Page404 },
  ];
}
