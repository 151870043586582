import Vue from 'vue';
import VueI18n from 'vue-i18n';
import langMessages from '@/lang';
import { env } from '@/constants';
import store from '../store';
import { localize } from 'vee-validate';
import axios from 'axios';

Vue.use(VueI18n);

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: window.localStorage.getItem('locale') || env.app.locale,
  fallbackLocale: env.app.fallbackLocale,
  messages: langMessages,
});

export const setI18nLanguage = (locale) => {
  i18n.locale = locale;
  localize(locale);

  axios.defaults.headers.common['Accept-Language'] = locale;
  document.querySelector('html').setAttribute('lang', locale);
};

localize(langMessages);

export default i18n;
