import router from '@/router';
import Vue from 'vue';
import endpoints from '@/constants/endpoints';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';

import {
  UPDATE_MEMBER,
  GET_MEMBERSHIP_TYPE,
} from '../actions/member';

const state = {
  memberShipType: {},
};
const actions = {
  async getMembershipType({ commit }) {
    try {
      const res = await http.get(endpoints.getMembershipTypes);
      const result = res.data;
      let options = result.filter(e => e.is_enabled)
        .map(item => {
          return {
            label: item.name,
            value: item.id,
          };
        });

      options = stringHelper.translateTextForDropdownList(options, 'MEMBERSHIP_TYPE');
      options.unshift(
        {
          value: 'all',
          label: responseHelper.getMessageByCode('MEMBERSHIP_TYPE_ALL'),
        },
        {
          value: 'Basic',
          label: responseHelper.getMessageByCode('MEMBERSHIP_TYPE_BASIC'),
        }
      );

      return options;
    }
    catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: responseHelper.getErrorMessage(err),
      });
    }
  },
};
const mutations = {
  [UPDATE_MEMBER]: (state, member) => (state.memberDetail = member),
  [GET_MEMBERSHIP_TYPE]: (state, member) => (state.memberShipType = member),
};
export default {
  state,
  actions,
  mutations
};
