import router from '@/router';
import Vue from 'vue';
import endpoints from '@/constants/endpoints';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import {
  UPDATE_ROLE,
  GET_SELECTED_ROLE,
  CREATE_ROLE,
} from '../actions/role';

const state = {
  roleSelected: {},
};

const actions = {
  async getSelectedRole({ commit }, role) {
    try {
      commit(GET_SELECTED_ROLE, role);
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  async addRole({ commit }, role) {
    try {
      const res = await http.post(endpoints.addRoles, role);

      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'Role has been created sucessful.'
      });
      router.push('/admin/roles');

    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
  async updateRole({ commit }, role) {
    try {
      const data = { ...role };
      delete data.id;

      const res = await http.put(endpoints.updateRole(role.id), data);
      Vue.notify({
        group: 'notifications',
        type: 'success',
        title: 'Success',
        text: 'Role has been updated sucessful.'
      });
      router.push('/admin/roles');
    } catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },
};

const mutations = {
  [GET_SELECTED_ROLE]: (state, role) => (state.roleSelected = role),
};

export default {
  state,
  actions,
  mutations,
};
