<template>
  <div id="app">
    <notifications group="notifications" />
    <router-view></router-view>
    <vue-progress-bar />
  </div>
</template>

<script>
import { AUTH_GET_USER_PERMISSIONS } from '@/store/actions/auth';
import { SETTINGS_GET_MEMBERSHIP_TYPES } from '@/store/actions/setting';
import { CURRENCY_GET_ALL_CURRENCIES } from '@/store/actions/currency';

export default {
  name: 'App',
  async mounted() {
    const isAuthenticated = this.$store.getters.isAuthenticated;
    this.$store.dispatch(CURRENCY_GET_ALL_CURRENCIES);

    if (isAuthenticated) {
      this.$store.dispatch(AUTH_GET_USER_PERMISSIONS);
      this.$store.dispatch(SETTINGS_GET_MEMBERSHIP_TYPES);
    }

    this.$Progress.finish();
  },
  created() {
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach((to, from) => {
      this.$Progress.finish();
    });
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import 'assets/scss/style';
@media (max-width: 320px) {
  .pagination {
    .page-item:first-child .page-link {
      border-top-left-radius: 0.2rem;
      border-bottom-left-radius: 0.2rem;
    }
    .page-link {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
    }
  }
}
#app {
  // Css notification
  .vue-notification-group {
    top: 15px !important;
    right: 15px !important;
    .vue-notification-wrapper {
      border-radius: 5px;
      box-shadow: -2px 2px 5px #cacaca;
      .vue-notification-template {
        color: #4f5363;
        padding: 0;
        background: #fff;
        border: none;
        margin: 0;
        &.success {
          color: #5da30b;
        }
        &.error {
          color: #ff0000;
        }
        .notification-title {
          padding: 10px 15px;
        }
        .notification-content {
          padding: 15px;
          color: #4f5363;
          background: #e8eaec;
          text-align: center;
        }
      }
    }
  }
  .form-control:focus {
    box-shadow: 0 0 5px #dadada !important;
  }
}
</style>
