export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_UPDATE_2FA_FLAG = 'AUTH_UPDATE_2FA_FLAG';
export const AUTH_TWOFA_CONFIRM = 'AUTH_TWOFA_CONFIRM';
export const AUTH_TWOFA_VERIFY = 'AUTH_TWOFA_VERIFY';
export const AUTH_TWOFA_UPDATE = 'AUTH_TWOFA_UPDATE';
export const AUTH_CLEAR_TWO_FA = 'AUTH_CLEAR_TWO_FA';
export const AUTH_CONFIRM_IP = 'AUTH_CONFIRM_IP';
export const AUTH_NAME_UPDATE = 'AUTH_NAME_UPDATE';
export const AUTH_GET_USER_PERMISSIONS = 'AUTH_GET_USER_PERMISSIONS ';
export const AUTH_UPDATE_USER_PERMISSIONS = 'AUTH_UPDATE_USER_PERMISSIONS';
