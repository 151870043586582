import router from '@/router';
import Vue from 'vue';
import { endpoints, env } from '@/constants';
import http from '@/utils/http';
import responseHelper from '@/utils/response-helper';
import {
  CURRENCY_GET_ALL_CURRENCIES,
  CURRENCY_GET_ALL_CURRENCIES_SUCCESS,
} from '@/store/actions/currency';

const state = {
  currencies: [],
};

const getters = {
  currencies: state => state.currencies,
};

const actions = {
  [CURRENCY_GET_ALL_CURRENCIES]: async (context) => {
    try {
      const result = await http.get(endpoints.getAllCurrencies, {});
      const items = result.data;

      context.commit(CURRENCY_GET_ALL_CURRENCIES_SUCCESS, items);
    }
    catch (err) {
      Vue.notify({
        group: 'notifications',
        type: 'error',
        title: 'Error',
        text: responseHelper.getErrorMessage(err),
      });
    }
  },

};

const mutations = {
  [CURRENCY_GET_ALL_CURRENCIES_SUCCESS]: (state, data) => {
    state.currencies = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
