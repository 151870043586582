export const languages = [
  {
    name: "English",
    value: "en"
  },
  {
    name: "日本語",
    value: "ja"
  },
  // {
  //   name: "한국어",
  //   value: "ko"
  // },
];

