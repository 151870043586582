const KEYS = {
  FilterClaimRequests: 'filter-claim-requests',
  FilterCryptoAddresses: 'filter-crypto-addresses',
  FilterUserAssets: 'filter-member-assets',
  FilterExchangeCurrencies: 'filter-exchange-currencies',
  FilterExchangeTransactions: 'filter-exchange-transactions',
  FilterCurrencies: 'filter-currencies',
  FilterEmailTrackings: 'fillter-email-trackings',
  FilterNotifications: 'fillter-notifications',
  FilterMSPointHistories: 'fillter-ms-point-histories',
  FilterLoggings: 'fillter-loggings',
  FilterFiatTransactions: 'fillter-fiat-transactions',
  FilterNexoMembers: 'fillter-nexo-member',
  FilterNexoTransaction: 'filter-nexo-transaction',
  FilterQuestions: 'filter-questions',
  FilterSurveys: 'filter-surveys',
  FilterAllAssets: 'filter-all-assets',
  FilterMoonpayCurrencies: 'filter-moonpay-currencies',
  FilterDApp: 'filter-dapp',
  FilterStakingDao: 'filter-staking-dao',
};

export const sessionStorage = {
  KEYS: KEYS,
  setData(key, filterData) {
    window.sessionStorage.setItem(key, JSON.stringify(filterData));
  },

  getData(key) {
    const dataString = window.sessionStorage.getItem(key) || '';
    if (!dataString) {
      return undefined;
    }

    try {
      return JSON.parse(dataString);
    } catch (error) {
      console.log(error, 'session storage error');
    }
  },
  clearData() {
    window.sessionStorage.clear();
  },
};

export default sessionStorage;
