export const platformStatuses = [
  {
    name: 'ENABLED',
    value: 1
  },
  {
    name: 'DISABLED',
    value: -1
  },
  {
    name: 'COMING_SOON',
    value: 0
  },
  // {
  //   name:'HIDDEN', //Add in sprint 3
  //   value:-2
  // }
];

export default platformStatuses;
